import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { LoggedGuard } from '@core/guards/logged.guard';
import { AuthGuard } from '@core/guards/auth.guard';
const routes: Routes = [
  {
    path: '',
    loadChildren: (): Promise<any> => import('./feature-modules/sales/sales.module').then((module) => module.SalesModule)
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: ''
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      onSameUrlNavigation: 'reload'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
