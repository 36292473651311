import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, tap } from 'rxjs';
import { AuthenticationService } from '../authentication/authentication.service';
import { EventsHubService } from '../events-hub/events-hub.service';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptorService implements HttpInterceptor {
  constructor(private readonly authService: AuthenticationService, private readonly eventHub: EventsHubService) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token:string | null = this.authService.getToken();
    const traceId:string = this.authService.getTraceId();

    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
          'x-trace-id': traceId,
        },
      });
    }

    return next.handle(request).pipe(
      tap({
        next: () => {
          //this.eventHub.setUnauthorizedError(false);
        },
        error: error => {
          if (error.status === 401) {
            this.eventHub.setUnauthorizedError(true);
          }
        },
      })
    );
  }
}
