import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Ticket } from '@shared/models';
import { ISearchTicket } from '@shared/interfaces';

@Injectable({
  providedIn: 'root',
})
export class EventsHubService {
  public loggedIn$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public ticketToEdit$: BehaviorSubject<Ticket | null> = new BehaviorSubject<Ticket | null>(null);
  public httpUnauthorizedError$: Subject<boolean> = new Subject();

  constructor() {}

  public setLoggedIn(value: boolean): void {
    this.loggedIn$.next(value);
  }

  public setTicketToEdit(value: Ticket | null): void {
    this.ticketToEdit$.next(value);
  }

  public setUnauthorizedError(value: boolean): void {
    this.httpUnauthorizedError$.next(value);
  }
}
