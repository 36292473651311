import { BaseEntity, IBoarding } from '@shared/models';
import { RouteRate } from './routeRate.model';

export class Schedule extends BaseEntity {
  public active: boolean;
  public name: string;
  public departureTime: string;
  public arrivalTime: string;
  public routeRate: RouteRate;
  public invalidBoardings?: IBoarding[] | null;

  constructor(obj: Partial<Schedule>) {
    super(obj);
    this.active = obj.active || false;
    this.name = obj.name || '';
    this.departureTime = obj.departureTime || '';
    this.arrivalTime = obj.arrivalTime || '';
    this.routeRate = obj.routeRate || new RouteRate({});
    this.invalidBoardings = obj.invalidBoardings || null;
  }
}
