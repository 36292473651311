import { BaseEntity, BusType, Location } from '@shared/models';

export class Route extends BaseEntity {
  public name: string;
  public departure: string;
  public destiny: string;
  public invoiceCounterNumber: InvoiceCounterNumber;
  public publicRoute: boolean;

  constructor(obj: Partial<Route>) {
    super(obj);
    this.name = obj.name || '';
    this.departure = obj.departure || '';
    this.destiny = obj.destiny || '';
    this.publicRoute = obj.publicRoute || true;
    this.invoiceCounterNumber = obj.invoiceCounterNumber ||
      { id: 0, prefix: '', name: '' };
  }
}

export interface InvoiceCounterNumber {
  id: number;
  prefix: string;
  name: string;
}
